import { Segment } from "#app/(unauthorized)/authentication/segment";
import { useJwt } from "#app/(unauthorized)/authentication/useJwt";
import { TimeSpan } from "#app/lib/TimeSpan";
import { remove } from "#app/lib/fetchClient";
import { getBlob, getJson, postJson } from "#app/lib/fetchClient";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import {
    queryOptions,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query";

export type SecureDocumentData = {
  documentId: number;
  lastAccessDate: Date;
  isSender: boolean;
  fileSize: number;
  mimeType: string;
  uploadDate: Date;
  expiryDate: Date;
  displayFilename: string;
};
export type DocumentExchange = {
  document: Document;
  notificationNote: string | null;
};

export type Document = {
  displayFilename: string;
  filename: string;
  file: string;
  mimeType: string;
};

type FinishDocumentGroupRequest = {
  notificationNote: string | null;
  documentIds: number[];
};

type PostDocumentNoGroupRequest = {
  document: Document;
  notificationNote: string | null;
};

const GATEWAY_PATH = "ExternalSecureDocument/v1";

export async function getDocumentsReceived(): Promise<SecureDocumentData[]> {
  const result = await getJson<SecureDocumentData[]>(
    `/${GATEWAY_PATH}/Documents/Received`,
  );
  return result;
}

export async function getDocumentsSent(): Promise<SecureDocumentData[]> {
  const result = await getJson<SecureDocumentData[]>(
    `/${GATEWAY_PATH}/Documents/Sent`,
  );
  return result;
}

export async function getDocumentContent(documentId: number): Promise<Blob> {
  const result = await getBlob(
    `/${GATEWAY_PATH}/Documents/Content?documentId=${documentId}`,
  );
  return result;
}

export async function postDocumentToRepCode(
  document: DocumentExchange,
): Promise<void> {
  const result = await postJson<void>(`/${GATEWAY_PATH}/Documents/ToRepCode`, {
    json: document,
  });
  return result;
}

export async function postCreateDocumentGroup(
  request: FinishDocumentGroupRequest,
): Promise<void> {
  const result = await postJson<void>(`/${GATEWAY_PATH}/Documents/ToRepCode/Group`, {
    json: request,
  });
  return result;
}

export async function postDocumentNoDocumentGroup(
  request: PostDocumentNoGroupRequest,
): Promise<number> {
  const result = await postJson<number>(
    `/${GATEWAY_PATH}/Documents/ToRepCode/Group/Document`,
    { json: request },
  );
  return result;
}

const STALE_TIME = TimeSpan.fromMinutes(1).totalMilliseconds;

export const secureDocumentQueryKeys = createQueryKeys("securedocs", {
  documentsReceived: {
    queryKey: null,
    queryFn: () =>
      getJson<SecureDocumentData[]>(`${GATEWAY_PATH}/Documents/Received`),
    contextQueries: {
      unreadCount: {
        queryKey: null,
        queryFn: () =>
          getJson<number>(`${GATEWAY_PATH}/Documents/Received/Count`),
      },
    },
  },
  documentsSent: {
    queryKey: null,
    queryFn: () =>
      getJson<SecureDocumentData[]>(`${GATEWAY_PATH}/Documents/Sent`),
  },
});

export function useSecureDocsUnreadCountQuery() {
  const jwt = useJwt();
  return useQuery(
    queryOptions({
      ...secureDocumentQueryKeys.documentsReceived._ctx.unreadCount,
      staleTime: STALE_TIME,
      enabled: jwt.segment !== Segment.NotLoggedIn,
    }),
  );
}

export function useDocumentsReceivedQuery() {
  const jwt = useJwt();
  return useQuery(
    queryOptions({
      ...secureDocumentQueryKeys.documentsReceived,
      staleTime: STALE_TIME,
      placeholderData: [],
      enabled: jwt.segment !== Segment.NotLoggedIn,
    }),
  );
}

export function useDocumentsSentQuery() {
  const jwt = useJwt();
  return useQuery(
    queryOptions({
      ...secureDocumentQueryKeys.documentsSent,
      staleTime: STALE_TIME,
      placeholderData: [],
      enabled: jwt.segment !== Segment.NotLoggedIn,
    }),
  );
}

export function useDocumentDelete() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (documentId: number) =>
      remove(`${GATEWAY_PATH}/Documents?documentId=${documentId}`),
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: secureDocumentQueryKeys._def,
      }),
  });
}
