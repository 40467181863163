"use client";

import * as secureDocumentService from "#app/_api/secure-document-service";
import { Icon } from "#ui/icon";
import {
  LinkButtonWithIndicator,
  type LinkButtonWithIndicatorProps,
  type LinkButtonWithIndicatorRef,
} from "#ui/link";
import { type NavRouteSub } from "#ui/nav.utils";
import { forwardRef } from "react";

export type LinkDocumentsReceivedRef = LinkButtonWithIndicatorRef;

export interface LinkDocumentsReceivedProps
  extends Omit<
    LinkButtonWithIndicatorProps,
    "children" | "count" | "href" | "iconOnly" | "size" | "variant"
  > {
  route: NavRouteSub;
}

const LinkDocumentsReceived = forwardRef<
  LinkDocumentsReceivedRef,
  LinkDocumentsReceivedProps
>(({ className, route, ...props }, ref) => {
  const secureDocsUnreadCountResult =
    secureDocumentService.useSecureDocsUnreadCountQuery();
  return (
    <LinkButtonWithIndicator
      {...props}
      href={route.path}
      count={secureDocsUnreadCountResult.data || 0}
      variant="ghost"
      iconOnly
      ref={ref}
    >
      <Icon name="ms-notifications-filled" size="lg" />
      <span className="sr-only">{route.element}:</span>
    </LinkButtonWithIndicator>
  );
});

LinkDocumentsReceived.displayName = "LinkDocumentsReceived";

export { LinkDocumentsReceived };
